import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import cn from 'classnames';
import dynamic from 'next/dynamic';

import type { TagType } from '@local-types/data';
import type { TRouter } from '@local-types/global';
import { UserTypes } from '@local-types/uxcat-types/types';

import Link from '@components/NextLink';
import PageSwitcher from '@components/PageSwitcher';
import UserDropdown from '@components/UserDropdown';
import MobileHeader from '@components/_biases/MobileHeader';
import UsfulLinksDropdown from '@components/UsfulLinksDropdown';
import UsefulLinksContent from '@components/UsefulLinksContent';
import { GlobalContext } from '@components/Context/GlobalContext';

import { navItems } from './navItems';

import useMobile from '@hooks/useMobile';
import useUXCoreGlobals from '@hooks/useUXCoreGlobals';

import { getMyInfo } from '@api/strapi';
import { userInfoUpdate } from '@api/uxcat/settings';

import toolHeaderData from '@data/toolHeader';

import PodcastIcon from '@icons/PodcastIcon';
import DiamondIcon from '@icons/DiamondIcon';
import CloseIcon from '@icons/CloseIcon';

import styles from './ToolHeader.module.scss';
import { getUserInfo } from '@api/uxcat/users-me';
import { isLevelMilestone } from '@lib/uxcat-helpers';

const SettingsModal = dynamic(() => import('@components/SettingsModal'), {
  ssr: false,
});

type TToolHeader = {
  page?: 'uxcp' | 'uxcg' | 'uxcore' | 'uxeducation' | 'uxcat';
  homepageLinkTarget?: '_blank' | '_self';
  tags: TagType[];
  openPodcast?: boolean;
  showSavedPersonas?: boolean;
  setOpenPodcast?: (updater: (prev: boolean) => boolean) => void;
  openPersonaModal?: (openPersona: boolean) => void;
  setUpdatedSettingsInfo?: (data: UserTypes) => void;
  changeUserUrl?: boolean;
  instantSave?: boolean;
  setSelectedTitle?: (selected: string) => void;
};

const ToolHeader: FC<TToolHeader> = ({
  page,
  homepageLinkTarget = '_self',
  openPodcast,
  tags,
  setOpenPodcast,
  openPersonaModal,
  showSavedPersonas = true,
  setUpdatedSettingsInfo,
  instantSave,
  changeUserUrl,
  setSelectedTitle,
}) => {
  const router = useRouter();
  const { isMobile } = useMobile()[1];
  const [, { isCoreView }] = useUXCoreGlobals();
  const { accountData, setAccountData } = useContext(GlobalContext);
  const { locale, asPath, locales } = router as TRouter;
  const {
    usefulLinksLabel,
    usernameIsTaken,
    settingsTxt,
    myProfileTxt,
    bobName,
    awarenessTest,
  } = toolHeaderData[locale];
  const imageSrc = useMemo(() => accountData?.picture, [accountData]);
  const [userInfo, setUserInfo] = useState(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showUxcoreTooltip, toggleUxcoreHeaderTooltip] = useState(true);
  const [showUxcgTooltip, toggleUxcgHeaderTooltip] = useState(true);
  const [openSettings, setOpenSettings] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [usernameIsTakenError, setUsernameIsTakenError] = useState('');
  const [updatedStrapiUserInfo, setUpdatedStrapiUserInfo] =
    useState(accountData);
  const [changedTitle, setChangedTitle] = useState(false);

  const currentUsername = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.username
    : accountData?.username;
  const currentEmail = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.email
    : accountData?.email;
  const publicEmail = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.publicEmail
    : accountData?.publicEmail;
  const linkedIn = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.linkedin
    : accountData?.linkedin;
  const linkedInStatus = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.publicLinkedin
    : accountData?.publicLinkedin;

  const changeTitlePermission = isLevelMilestone(userInfo?.level, 'M3');

  const userTitlesRu = ['Просвещенный', 'Профессор', 'Великий'];
  const russianTitles = selectedTitle => {
    if (!selectedTitle) {
      return null;
    }
    return selectedTitle === 'Enlightened'
      ? userTitlesRu[0]
      : selectedTitle === 'Professor'
      ? userTitlesRu[1]
      : userTitlesRu[2];
  };

  const title = changedTitle ? updatedStrapiUserInfo?.title : userInfo?.title;
  const openPodcastHandler = useCallback(() => {
    setOpenPodcast(prev => !prev);
  }, []);

  const detectingLangSwitch = () => {
    if (asPath.includes('/user/')) {
      localStorage.setItem('languageSwitched', 'true');
    }
  };

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleSaveClick = async (
    username: string,
    linkedInUrl: string,
    isEmailPublic: string,
    isLinkedinPublic: string,
    title?: string,
  ) => {
    const mailIsPublic = isEmailPublic === 'everyone';
    const linkedInIsPublic = isLinkedinPublic === 'everyone';
    try {
      await userInfoUpdate(
        token,
        username,
        linkedInUrl,
        mailIsPublic,
        linkedInIsPublic,
        title,
      );
      const data = await getMyInfo();
      changeUserUrl && router.replace(`/user/${username}`);
      setUpdatedStrapiUserInfo(data);
      instantSave && setUpdatedSettingsInfo(data);
      setOpenSettings(false);
      setUsernameIsTakenError('');
    } catch (error) {
      setOpenSettings(true);
      setUsernameIsTakenError(usernameIsTaken);
    }
  };

  useEffect(() => {
    const initialUxcoreTooltip = JSON.parse(
      localStorage.getItem('toggleUxcoreHeaderTooltipNew') || 'true',
    );
    const initialUxcgTooltip = JSON.parse(
      localStorage.getItem('toggleUxcgHeaderTooltipNew') || 'true',
    );

    toggleUxcoreHeaderTooltip(initialUxcoreTooltip);
    toggleUxcgHeaderTooltip(initialUxcgTooltip);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'toggleUxcoreHeaderTooltipNew',
      JSON.stringify(showUxcoreTooltip),
    );
  }, [showUxcoreTooltip]);

  useEffect(() => {
    localStorage.setItem(
      'toggleUxcgHeaderTooltipNew',
      JSON.stringify(showUxcgTooltip),
    );
  }, [showUxcgTooltip]);

  useEffect(() => {
    const token =
      (typeof window !== undefined && localStorage.getItem('accessToken')) ||
      localStorage.getItem('googleToken');
    setToken(token);
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await getUserInfo();
        setUserInfo(data?.user);
      } catch (err) {
        setUserInfo(err.message);
      }
    };

    if (token) {
      fetchUserInfo().then(r => r);
    }
  }, [token]);

  useEffect(() => {
    if (userInfo) {
      setSelectedTitle &&
        setSelectedTitle(locale === 'en' ? title : russianTitles(title));
    }
  }, [title, locale]);
  return (
    <header className={styles.ToolHeader}>
      {isMobile ? (
        <>
          <MobileHeader />
          <div className={styles.PageSwitcherContainer}>
            <UsfulLinksDropdown tags={tags} page={page} />
            <PageSwitcher page={page} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.LinkWrapper}>
            <Link href="/" locale={locale}>
              <a target={homepageLinkTarget} className={styles.logo}>
                <Image
                  src={'/assets/logos/keepsimple.svg'}
                  alt="keepsimple logo"
                  width={130.61}
                  height={25.87}
                />
              </a>
            </Link>
            <div className={styles.Links}>
              {navItems.map(({ label, href, page: itemPage, icon }, index) => (
                <Link key={index} href={href} locale={locale}>
                  <a
                    className={cn(styles.MenuItem, {
                      [styles.Active]: itemPage === page,
                    })}
                    target={label === 'Bob - AI Assistant' ? '_blank' : '_self'}
                    onClick={() => {
                      toggleUxcoreHeaderTooltip(false);
                      toggleUxcgHeaderTooltip(false);
                    }}
                  >
                    {label != 'Bob - AI Assistant' ? (
                      icon
                    ) : (
                      <img
                        src={'/assets/Bob.png'}
                        alt={'Bob - AI Assistant'}
                        width={25}
                        height={25}
                        className={styles.bob}
                      />
                    )}
                    <span className={styles.Description}>
                      {label === 'Bob - AI Assistant'
                        ? bobName
                          ? bobName
                          : label
                        : label === 'Awareness Test'
                        ? awarenessTest
                        : label}
                    </span>
                  </a>
                </Link>
              ))}
            </div>

            {showUxcgTooltip && asPath === '/uxcore' && (
              <div className={cn(styles.headerTooltipUxCore)}>
                <span>
                  {locale === 'en'
                    ? 'Find solutions for your problems'
                    : 'Находим решения ваших проблем'}{' '}
                </span>
                <button
                  className={styles.closeBtn}
                  onClick={() => {
                    toggleUxcgHeaderTooltip(false);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            )}
            {showUxcoreTooltip && asPath === '/uxcg' && (
              <div className={cn(styles.headerTooltipUxcg)}>
                <span>
                  {locale === 'en'
                    ? 'Learn human thinking patterns '
                    : 'Изучаем паттерны мышления'}
                </span>
                <button
                  className={styles.closeBtn}
                  onClick={() => {
                    toggleUxcoreHeaderTooltip(false);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            )}
          </div>

          <div
            className={cn(styles.UsefulLinksWrapper, {
              [styles.authorized]: !!accountData,
            })}
          >
            {isCoreView && asPath === '/uxcore' && (
              <div
                onClick={openPodcastHandler}
                className={cn(styles.MenuItem, {
                  [styles.Active]: !!openPodcast,
                })}
              >
                <PodcastIcon />
                <span>{locale === 'en' ? 'Podcast' : 'Подкаст'}</span>
              </div>
            )}
            <span className={styles.MenuItem}>
              <DiamondIcon />
              <span className={styles.Description}>{usefulLinksLabel}</span>
              <UsefulLinksContent tags={tags} page={page} />
            </span>
            <div
              className={cn(styles.actions, {
                [styles.authorized]: !!accountData,
              })}
            >
              <div
                className={cn(styles.toggleLanguage, {
                  [styles.en]: locale === 'en',
                  [styles.ru]: locale === 'ru',
                })}
              >
                <Link shallow={true} href={asPath} locale={locales[0]}>
                  <a
                    onClick={() => {
                      detectingLangSwitch();
                    }}
                  >
                    {locales[0]}
                  </a>
                </Link>
                <span className={styles.toggleLanguageDivider}>|</span>
                <Link shallow={true} href={asPath} locale={locales[1]}>
                  <a
                    onClick={() => {
                      detectingLangSwitch();
                    }}
                  >
                    {locales[1]}
                  </a>
                </Link>
              </div>
              <div className={styles.userMenu}>
                <UserDropdown
                  userName={currentUsername}
                  userImage={imageSrc}
                  showDropdown={showDropdown}
                  isLoggedIn={!!accountData}
                  setShowDropdown={setShowDropdown}
                  setAccountData={setAccountData}
                  openSavedPersonas={openPersonaModal}
                  showSavedPersonas={showSavedPersonas}
                  handleOpenSettings={handleOpenSettings}
                  settingsTxt={settingsTxt}
                  myProfileTxt={myProfileTxt}
                />
              </div>
            </div>
          </div>
          {openSettings && (
            <SettingsModal
              setOpenSettings={setOpenSettings}
              currentUsername={currentUsername}
              currentEmail={currentEmail}
              mailStatus={publicEmail}
              linkedin={linkedIn}
              linkedinStatus={linkedInStatus}
              handleSaveClick={handleSaveClick}
              setUsernameIsTakenError={setUsernameIsTakenError}
              usernameIsTakenError={usernameIsTakenError}
              defaultSelectedTitle={
                locale === 'en' ? title : russianTitles(title)
              }
              changeTitlePermission={changeTitlePermission}
              setChangedTitle={setChangedTitle}
            />
          )}
        </>
      )}
    </header>
  );
};

export default ToolHeader;
