const UXCPIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8787 9.57038C16.7673 11.1032 16.2138 12.5589 15.2795 13.7807C14.3446 15.0024 13.083 15.9182 11.63 16.4278C10.1788 16.938 8.62121 17.0139 7.12496 16.6461C5.62983 16.2782 4.28546 15.4884 3.23696 14.3623C2.18846 13.2368 1.49771 11.8412 1.23839 10.3258C0.97908 8.811 1.16696 7.26525 1.78177 5.85563C2.39658 4.446 3.40233 3.25575 4.68989 2.41369C5.97858 1.5705 7.47314 1.125 9.0127 1.125V0C7.25377 0 5.54602 0.509062 4.07452 1.4715C2.60302 2.43394 1.45383 3.79406 0.751268 5.40506C0.0481427 7.01606 -0.16617 8.78288 0.129705 10.5148C0.42558 12.2468 1.21589 13.842 2.41402 15.1284C3.61214 16.4149 5.14833 17.3171 6.85664 17.7373C7.5682 17.9128 8.29214 18 9.01496 18C10.0269 18 11.036 17.8284 12.0035 17.4887C13.6629 16.9065 15.1046 15.8602 16.1728 14.4636C17.241 13.068 17.8732 11.4041 18.0003 9.65138L16.8787 9.57038Z"
      fill="#515151"
    />
    <path
      d="M17.9371 7.75284C17.6772 5.89434 16.8582 4.18997 15.5678 2.82422L14.7505 3.59653C15.8789 4.79128 16.5955 6.28247 16.8233 7.90866L17.9371 7.75284Z"
      fill="#515151"
    />
    <path
      d="M14.7207 2.03587C14.4057 1.7788 14.0721 1.54199 13.7284 1.33105L13.1406 2.29012C13.441 2.47462 13.7329 2.68218 14.0086 2.90718L14.7207 2.03587Z"
      fill="#515151"
    />
    <path
      d="M7 13H6C6 12.606 6.0776 12.2159 6.22836 11.8519C6.37913 11.488 6.6001 11.1573 6.87868 10.8787C7.15726 10.6001 7.48797 10.3791 7.85195 10.2284C8.21593 10.0776 8.60603 10 9 10C9.39397 10 9.78407 10.0776 10.1481 10.2284C10.512 10.3791 10.8427 10.6001 11.1213 10.8787C11.3999 11.1573 11.6209 11.488 11.7716 11.8519C11.9224 12.2159 12 12.606 12 13H11L7 13Z"
      fill="#515151"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6C11 7.10457 10.1046 8 9 8C7.89543 8 7 7.10457 7 6C7 4.89543 7.89543 4 9 4C10.1046 4 11 4.89543 11 6ZM10.8559 8.3572C11.5527 7.80784 12 6.95616 12 6C12 4.34315 10.6569 3 9 3C7.34315 3 6 4.34315 6 6C6 6.95616 6.44732 7.80784 7.1441 8.3572C7.12488 8.36488 7.10571 8.37268 7.08658 8.3806C6.47995 8.63188 5.92876 9.00017 5.46447 9.46447C5.00017 9.92876 4.63188 10.48 4.3806 11.0866C4.12933 11.6932 4 12.3434 4 13H5C5 12.4747 5.10346 11.9546 5.30448 11.4693C5.5055 10.984 5.80014 10.543 6.17157 10.1716C6.54301 9.80014 6.98396 9.5055 7.46927 9.30448C7.95457 9.10346 8.47471 9 9 9C9.52529 9 10.0454 9.10346 10.5307 9.30448C11.016 9.5055 11.457 9.80014 11.8284 10.1716C12.1999 10.543 12.4945 10.984 12.6955 11.4693C12.8965 11.9546 13 12.4747 13 13H14C14 12.3434 13.8707 11.6932 13.6194 11.0866C13.3681 10.48 12.9998 9.92876 12.5355 9.46447C12.0712 9.00017 11.52 8.63188 10.9134 8.3806C10.8943 8.37268 10.8751 8.36488 10.8559 8.3572Z"
      fill="#515151"
    />
    <defs>
      <clipPath id="clip0_2655_5809">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UXCPIcon;
