import React, { FC, Fragment } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import useMobile from '@hooks/useMobile';

import Tooltip from '../Tooltip';

import styles from './Tag.module.scss';

type TTagStyles = {
  backgroundColor: string;
};

export type TTag = {
  dataId?: string | number;
  id?: string | number;
  tooltip?: {
    titleEn: string;
    titleRu: string;
  };
  tooltipPosition?: 'default' | 'bottom';
  titleEn: string;
  titleRu: string;
  styles: TTagStyles;
  large?: boolean;
  type?: 'default' | 'button';
  isActive?: boolean;
  onClick?:  React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

const Tag: FC<TTag> = ({
  dataId,
  titleEn,
  titleRu,
  tooltip,
  tooltipPosition,
  styles: incomingStyles,
  type = 'default',
  large,
  isActive = false,
  onClick = () => {},
  className
}: TTag) => {
  const { isMobile } = useMobile()[1];
  const { locale } = useRouter();
  const advancedProps: any = {};
  let Wrapper: any = Fragment;

  if (tooltip && !isMobile) {
    Wrapper = Tooltip;
    advancedProps.content = locale === 'en' ? tooltip.titleEn : tooltip.titleRu;
    advancedProps.isOnBottom = tooltipPosition === 'bottom';
  }

  const title = locale === 'en' ? titleEn : titleRu;

  return (
    <Wrapper {...advancedProps}>
      <button
        data-id={dataId}
        className={cn(styles.Tag, {
          [styles.TagButton]: type === 'button',
          [styles.Large]: large,
          [styles.TagButtonActive]: isActive,
          [className]: !!className,
        })}
        onClick={type === 'button' ? onClick : () => {}}
        style={incomingStyles}
      >
         {title}
      </button>
    </Wrapper>
  );
};

export default Tag;
