const UXCatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      d="M13.2 13.4999H13.1211L13.6992 12.5708C13.7649 12.4652 13.8 12.3411 13.8
       12.2142V6.42869C13.8 0.0734152 9.04797 0 8.99997 0C8.84089 -2.13055e-08
        8.68832 0.0676865 8.57581 0.188178C8.46329 0.30867 8.40005 0.472104 8.39997
         0.642547L8.39967 1.92828H7.79996C7.67491 1.92817 7.55299 1.97011 7.45136 
         2.04818L3.25134 5.26252C3.14691 5.34242 3.06913 5.45609 3.02943 5.58684C2.98973 
         5.71758 2.99021 5.85852 3.03078 5.98896L3.63078 7.91757C3.67502 8.06051 3.76479
          8.18221 3.88436 8.26134C4.00394 8.34048 4.14569 8.37202 4.28479 8.35041L7.2068 
          7.90311L4.89109 11.8735C4.82987 11.9784 4.79823 12.1 4.79994 12.2237C4.80165 
          12.3475 4.83666 12.468 4.90075 12.571L5.47885 13.4999H5.39995C4.92272 13.5005 
          4.46518 13.7039 4.12773 14.0654C3.79028 14.427 3.60047 14.9172 3.59994
           15.4285V18H15V15.4285C14.9995 14.9172 14.8097 14.427 14.4722 14.0654C14.1348 
           13.7039 13.6772 13.5005 13.2 13.4999ZM8.90883 7.41195C8.96947 7.30804 9.00112 
           7.18773 9.00006 7.06518C8.999 6.94264 8.96527 6.82297 8.90285 6.72029C8.84043 
           6.6176 8.75191 6.53616 8.64773 6.48558C8.54355 6.435 8.42804 6.41738 8.31483 
           6.43479L4.61053 7.00174L4.30902 6.03242L7.99196 3.21434H8.99937C9.15845 3.21434
            9.31102 3.14666 9.42353 3.02617C9.53604 2.90567 9.59929 2.74224 9.59937 
            2.5718L9.59997 1.36828C10.5008 1.58345 12.13 2.35901 12.516 
            5.14263H10.8V6.42837H12.6V7.7141H10.8V8.99984H12.6V10.2856H10.8V11.5713H12.6V12.0196L11.6789 
            13.4999H6.92084L6.11395 12.2029L8.90883 7.41195ZM13.8 16.7143H4.79995V15.4285C4.80009 15.2581 
            4.86335 15.0946 4.97584 14.9741C5.08833 14.8536 5.24086 14.7858 5.39995 14.7857H13.2C13.3591 
            14.7858 13.5117 14.8535 13.6242 14.9741C13.7367 15.0946 13.7999 15.2581 13.8 15.4285V16.7143Z"
    />
  </svg>
);
export default UXCatIcon;
