import { useRouter } from 'next/router';
import { FC, useCallback, useState } from 'react';

import Modal from '@components/Modal';

import { TRouter } from '@local-types/global';

import Dropdown from '@components/Dropdown';
import Textarea from '@components/Textarea';
import Input from '@components/Input';
import Checkbox from '@components/Checkbox';
import Button from '@components/Button';

import settingsData from '@data/uxcat/settings';

import styles from './SettingsModal.module.scss';

type SettingsModalProps = {
  setOpenSettings: (openSettings: boolean) => void;
  currentUsername: string;
  currentEmail: string;
  defaultSelectedTitle?: string;
  mailStatus?: boolean;
  linkedin?: string;
  linkedinStatus?: boolean;
  changeTitlePermission?: boolean;
  usernameIsTakenError?: string;
  setUsernameIsTakenError: (usernameIsTakenError: string) => void;
  setChangedTitle: (selected: boolean) => void;
  handleSaveClick: (
    username: string,
    linkedInUrl: string,
    isEmailPublic: string,
    isLinkedinPublic: string,
    title?: string,
  ) => void;
};

const SettingsModal: FC<SettingsModalProps> = ({
  setOpenSettings,
  currentUsername,
  handleSaveClick,
  currentEmail,
  mailStatus,
  linkedinStatus,
  linkedin,
  usernameIsTakenError,
  setUsernameIsTakenError,
  defaultSelectedTitle,
  changeTitlePermission,
  setChangedTitle,
}) => {
  const router = useRouter();
  const { locale } = router as TRouter;
  const [isEmailPublic, setIsEmailPublic] = useState(
    !!mailStatus ? 'everyone' : 'onlyMe',
  );
  const [isLinkedinPublic, setIsLinkedinPublic] = useState(
    !!linkedinStatus ? 'everyone' : 'onlyMe',
  );
  const [username, setUsername] = useState(currentUsername);
  const [linkedInUrl, setLinkedInUrl] = useState(linkedin);
  const [selectedTitle, setSelectedTitle] = useState(defaultSelectedTitle);
  const [isValid, setIsValid] = useState({ username: true, linkedin: true });

  const {
    title,
    selectTitle,
    usernameTxt,
    email,
    visible,
    everyone,
    onlyYou,
    linkedIn,
    saveBtn,
    cancelBtn,
    usernameValidationMessage,
  } = settingsData[locale];

  const userTitlesEn = ['Enlightened', 'Professor', 'Greatest'];
  const userTitlesRu = ['Просвещенный', 'Профессор', 'Великий'];
  const userTitles = locale === 'en' ? userTitlesEn : userTitlesRu;
  
  const closeSettings = () => {
    setOpenSettings(false);
    setUsernameIsTakenError('');
  };

  const linkedInRegex =
    /^(https?:\/\/)?(www\.)?(linkedin\.com\/in\/|lnkd\.in\/)[a-zA-Z0-9-]{5,30}\/?$/;
  const usernameRegex = /^[^\s]{6,30}$/;

  const validateUsername = (username: string) => {
    return usernameRegex.test(username);
  };

  const validateLinkedIn = (linkedInUrl: string) => {
    return linkedInRegex.test(linkedInUrl);
  };

  const handleValidation = useCallback(
    // But this ain't good pardner
    (value, type) => {
      let isValidInput = false;
      if (type === 'username') {
        isValidInput = validateUsername(username);
      } else if (type === 'linkedin') {
        isValidInput = validateLinkedIn(linkedInUrl);
      }

      setIsValid(prevIsValid => ({
        ...prevIsValid,
        [type]: isValidInput,
      }));
    },
    [isValid],
  );

  const handleSave = () => {
    if (isValid.username && isValid.linkedin) {
      handleSaveClick(
        username,
        linkedInUrl,
        isEmailPublic,
        isLinkedinPublic,
        selectedTitle,
      );
    }
    setChangedTitle && setChangedTitle(true);
  };

  return (
    <Modal
      title={title}
      hasBorder
      onClick={closeSettings}
      blackTitle
      removeBorderMobile
      fullSizeMobile
      bodyClassName={styles.modalBody}
    >
      {/*TODO - Add this according to the level*/}
      <div>
        {changeTitlePermission && (
          <div>
            <h4 className={styles.title}>{selectTitle}</h4>
            <Dropdown
              selected={defaultSelectedTitle}
              values={userTitles}
              sendingValues={userTitlesEn}
              isWide
              className={styles.dropdownArrow}
              onChange={value => {
                setSelectedTitle(value);
              }}
            />
          </div>
        )}
        <div>
          <h4 className={styles.username}>{usernameTxt}</h4>
          <Textarea
            text={username}
            className={styles.usernameEdit}
            onChange={value => {
              setUsername(value);
              if (usernameIsTakenError) setUsernameIsTakenError('');
            }}
            validationFunction={v => !!v}
            isValidCallback={v => handleValidation(v, 'username')}
            showError={!isValid.username || !!usernameIsTakenError}
            errorMessage={
              usernameIsTakenError
                ? usernameIsTakenError
                : usernameValidationMessage
            }
          />
        </div>
        <div>
          <h4 className={styles.email}> {email}</h4>
          <Input disabled placeholder={currentEmail} />
          <Checkbox
            visibleTxt={visible}
            everyone={everyone}
            onlyYou={onlyYou}
            setRadioValue={setIsEmailPublic}
            radioValue={isEmailPublic}
          />
        </div>
        <div>
          <h4 className={styles.linkedin}> {linkedIn}</h4>
          <Textarea
            text={linkedInUrl}
            className={styles.usernameEdit}
            onChange={value => {
              setLinkedInUrl(value);
            }}
            validationFunction={v => !!v}
            isValidCallback={v => handleValidation(v, 'linkedin')}
            showError={!isValid.linkedin}
            errorMessage={'Please enter a valid LinkedIn URL.'}
          />
          <Checkbox
            visibleTxt={visible}
            everyone={everyone}
            onlyYou={onlyYou}
            setRadioValue={setIsLinkedinPublic}
            radioValue={isLinkedinPublic}
          />
        </div>
        <div className={styles.btnWrapper}>
          <Button
            label={cancelBtn}
            onClick={closeSettings}
            className={styles['btn']}
          />
          <Button
            label={saveBtn}
            onClick={() => {
              handleSave();
            }}
            type="primary"
            className={styles['btn']}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SettingsModal;
