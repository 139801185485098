export const findLevelDetail = (
  userLevel: number,
  allLevels: any[],
): any | undefined => {
  return allLevels?.find(detail => {
    if (detail && detail.attributes) {
      return detail.attributes.level === userLevel;
    }
  });
};

export function normalizeUserData(data) {
  if (data && data.user) {
    return data.user;
  }
  return data;
}

export const isLevelMilestone = (level, mileStoneLevel) => {
  const milestoneLevels = ['M1', 'M2', 'M3', 'M4', 'M5'];
  const levelIndex = milestoneLevels.indexOf(level);
  const m2Index = milestoneLevels.indexOf(mileStoneLevel);
  return levelIndex >= m2Index;
};

export const formatDate = isoString => {
  const date = new Date(isoString);
  return date.toLocaleDateString('en-GB').replace(/\//g, '.');
};

export const cleanTitle = (title: string): string => {
  return title.replace(/#\d+\s*/, '');
};
