const UXCGIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.006 17.014C29.808 19.739 28.824 22.327 27.163 24.499C25.501 26.671 23.258 28.299 20.675 29.205C18.094 30.112 15.324 30.247 12.666 29.593C10.009 28.939 7.61796 27.535 5.75396 25.534C3.88996 23.532 2.66196 21.051 2.20096 18.357C1.73996 15.664 2.07396 12.916 3.16696 10.411C4.25996 7.905 6.04796 5.789 8.33696 4.292C10.628 2.792 13.285 2 16.022 2V0C12.895 0 9.85896 0.905 7.24296 2.616C4.62696 4.328 2.58396 6.746 1.33496 9.609C0.0849631 12.473 -0.296037 15.614 0.229963 18.693C0.755963 21.771 2.16096 24.608 4.29096 26.896C6.42096 29.183 9.15196 30.786 12.188 31.533C13.453 31.845 14.74 32 16.025 32C17.824 32 19.618 31.696 21.339 31.091C24.289 30.056 26.852 28.196 28.751 25.713C30.65 23.232 31.774 20.274 32 17.158L30.006 17.014Z"
      fill="#515151"
    />
    <path
      d="M31.8879 13.784C31.4259 10.48 29.9699 7.44897 27.6759 5.02197L26.2229 6.39497C28.2289 8.51897 29.5029 11.17 29.9079 14.061L31.8879 13.784Z"
      fill="#515151"
    />
    <path
      d="M26.1701 3.61903C25.6101 3.16203 25.0171 2.74103 24.4061 2.36603L23.3611 4.07103C23.8951 4.39903 24.4141 4.76803 24.9041 5.16803L26.1701 3.61903Z"
      fill="#515151"
    />
    <path
      d="M13.989 8.00002H8.01099C7.45899 8.00002 7.01099 8.44802 7.01099 9.00002V22.077C7.01099 22.63 7.45899 23.077 8.01099 23.077H13.988C14.291 23.077 14.705 23.077 15.062 24.283C15.066 24.296 15.075 24.306 15.079 24.319C15.094 24.364 15.119 24.403 15.14 24.444C15.171 24.504 15.201 24.563 15.242 24.615C15.27 24.65 15.303 24.679 15.335 24.71C15.384 24.757 15.433 24.8 15.49 24.835C15.528 24.859 15.567 24.878 15.609 24.897C15.673 24.926 15.737 24.948 15.806 24.963C15.831 24.969 15.852 24.983 15.878 24.987C15.925 24.995 15.972 24.997 16.019 24.997C16.02 24.997 16.02 24.997 16.021 24.997H16.022H16.023C16.024 24.997 16.024 24.997 16.025 24.997C16.072 24.997 16.118 24.994 16.166 24.987C16.192 24.983 16.213 24.969 16.238 24.963C16.307 24.948 16.371 24.926 16.434 24.897C16.476 24.878 16.516 24.859 16.554 24.834C16.61 24.799 16.658 24.756 16.706 24.711C16.74 24.679 16.773 24.649 16.802 24.613C16.842 24.562 16.872 24.504 16.902 24.445C16.924 24.402 16.948 24.363 16.964 24.317C16.968 24.304 16.977 24.294 16.981 24.281C17.338 23.075 17.752 23.075 18.055 23.075H24.033C24.586 23.075 25.033 22.628 25.033 22.075V8.99902C25.033 8.44702 24.586 7.99902 24.033 7.99902H18.055C17.212 7.99902 16.531 8.31602 16.022 8.79002C15.513 8.31702 14.831 8.00002 13.989 8.00002ZM13.989 21.077H9.01099V9.99902H13.988C14.741 9.99902 15.021 10.655 15.021 11.068V21.25C14.708 21.134 14.363 21.077 13.989 21.077ZM18.055 9.99902H23.033V21.076H18.055C17.68 21.076 17.336 21.134 17.022 21.249V11.068C17.022 10.655 17.302 9.99902 18.055 9.99902Z"
      fill="#515151"
    />
  </svg>
);

export default UXCGIcon;
