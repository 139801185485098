import UXCGIcon from "@icons/UXCGIcon";
import UXCPIcon from "@icons/UXCPIcon";
import UXCatIcon from "@icons/UXCatIcon";
import UXCoreIcon from "@icons/UXCoreIcon";

const isProduction = process.env.NEXT_PUBLIC_ENV === 'prod';

const navItemWithoutUXCat =
  [
    {
      label: 'UX Core',
      href: '/uxcore',
      page: 'uxcore',
      icon: <UXCoreIcon />,
    },
    {
      label: 'Guide',
      href: '/uxcg',
      page: 'uxcg',
      icon: <UXCGIcon />,
    },
    {
      label: 'Persona',
      href: '/uxcp',
      page: 'uxcp',
      icon: <UXCPIcon />,
    },
    {
      label: 'Bob - AI Assistant',
      href: 'https://chat.openai.com/g/g-BtuSiGF18-bob-trickery-and-deception-by-ux-core',
      page: '',
      icon: '',
    },
  ];

const navItemWithUXCat =
  [
    {
      label: 'UX Core',
      href: '/uxcore',
      page: 'uxcore',
      icon: <UXCoreIcon />,
    },
    {
      label: 'Guide',
      href: '/uxcg',
      page: 'uxcg',
      icon: <UXCGIcon />,
    },
    {
      label: 'Persona',
      href: '/uxcp',
      page: 'uxcp',
      icon: <UXCPIcon />,
    },
    {
      label: 'Awareness Test',
      href: '/uxcat',
      page: 'uxcat',
      icon: <UXCatIcon />,
    },
    {
      label: 'Bob - AI Assistant',
      href: 'https://chat.openai.com/g/g-BtuSiGF18-bob-trickery-and-deception-by-ux-core',
      page: '',
      icon: '',
    },
  ];

export const navItems = isProduction ? navItemWithoutUXCat : navItemWithUXCat;