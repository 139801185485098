import en from './en';
import ru from './ru';

interface TUsefulLinkType {
  title: string;
  link: string;
  icon: string;
  alt?: string;
  target: string;
  download: boolean;
}

interface TUsefulLinks {
  title: string;
  items: TUsefulLinkType[];
}

interface TToolHeaderData {
  usefulLinksLabel: string;
  usernameIsTaken: string;
  settingsTxt: string;
  myProfileTxt: string;
  bobName: string;
  awarenessTest: string;
  usefulLinks: TUsefulLinks[];
}

export default { en, ru } as { en: TToolHeaderData; ru: TToolHeaderData };
