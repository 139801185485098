const en = {
  feedbackLabel: `Your feedback is vital for our project development`,
  feedbackError: `field is required`,
  emailLabel: `Your email (optional)`,
  emailPlaceholder: `Email`,
  emailError: `invalid email`,
  info1: `You can also write Wolf directly in`,
  info2: `or via email`,
  cancelButtonLabel: `Cancel`,
  submitButtonLabel: `Submit`,
};

export default en;
