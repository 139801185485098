import { UxCatRoute } from '@api/uxcat/configs';

export const getUserInfo = async () => {
  const url = `${UxCatRoute}users/me`;
  const accessToken = localStorage.getItem('accessToken');

  const headers = {
    'Content-Type': 'application/json',
    accessToken: accessToken,
  };
  return await fetch(url, {
    method: 'GET',
    headers,
  }).then(data => data.json());
};
