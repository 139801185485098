export const getCountributors = async () => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/contributor`;
  const result = await Promise.all([
    fetch(url)
      .then(data => data.json())
      .then(data => data?.data?.attributes?.content),
    fetch(`${url}?locale=ru`)
      .then(data => data.json())
      .then(data => data?.data?.attributes?.content),
  ]);

  return {
    en: result[0],
    ru: result[1],
  };
};
