const ru = {
  info1: `Если вам нравится проект, вы можете поддержать нас лишней криптой.`,
  info2: `Больше ресурсов позволит нам мыслить шире, и, как следствие, повысить скорость разработки уникальных инструментов для общества.`,
  ourTeam: 'Участники',
  copyButton: 'Копировать',
  copied: 'Скопировано',
  cancelButtonLabel: `Закрыть`,
  authors: [
    {
      role: 'Визуальный дизайн:',
      link: 'https://www.behance.net/dee374',
      name: 'Dee',
    },
    {
      role: 'Фронтенд разработка:',
      link: 'https://www.linkedin.com/in/alchangyan/',
      name: 'Артем Алчангян',
    },
    {
      role: 'Бэкенд имплементация:',
      link: 'https://www.linkedin.com/in/melkonyanvahan/',
      name: 'Ваган Мелконян',
    },
    {
      role: 'Редактирование UX Core:',
      link: 'https://www.facebook.com/narine.madunts',
      name: 'Наринэ Мадунц',
    },
    {
      role: 'Редактирование UX Core Guide:',
      link: 'https://www.linkedin.com/in/adilkhanyanlusine/',
      name: 'Лусинэ Адилханян',
    },
    {
      role: 'Техническая поддержка:',
      link: 'https://www.linkedin.com/in/s-m-hovhannisyan/',
      name: 'Саркис Оганисян',
    },
    {
      role: 'Идея проекта, исследования, валидация данных, создание контента (Русский и Английский) и разработка логики фич',
      link: 'https://www.linkedin.com/in/alexanyan/',
      name: 'Вольф Алексанян',
    },
  ],
};

export default ru;
