const en = {
  info1: `If you like the project, you can support us with your spare crypto.`,
  info2: `More resources will allow us to think broader and speed up the development of exciting features.`,
  ourTeam: 'Our contributors',
  copyButton: 'Copy',
  copied: 'Copied',
  cancelButtonLabel: `Close`,
  authors: [
    {
      role: 'Visual design by',
      link: 'https://www.behance.net/dee374',
      name: 'Dee',
    },
    {
      role: 'Frontend implementation by',
      link: 'https://www.linkedin.com/in/alchangyan/',
      name: 'Artem Alchangyan',
    },
    {
      role: 'Backend implementation by',
      link: 'https://www.linkedin.com/in/melkonyanvahan/',
      name: 'Vahan Melkonyan',
    },
    {
      role: 'UX Core text editing by',
      link: 'https://www.facebook.com/narine.madunts',
      name: 'Narine Madunts',
    },
    {
      role: 'UX Core Guide text editing by',
      link: 'https://www.linkedin.com/in/adilkhanyanlusine/',
      name: 'Lusine Adilkhanyan',
    },
    {
      role: 'Technical support by',
      link: 'https://www.linkedin.com/in/s-m-hovhannisyan/',
      name: 'Sargis Hovhannisyan',
    },
    {
      role: 'Project idea, research, data validation, content creation (Ru+En), and feature logic design by',
      link: 'https://www.linkedin.com/in/alexanyan/',
      name: 'Wolf Alexanyan',
    },
  ],
};

export default en;
