import { FC, useCallback, useEffect } from 'react';
import Link from '@components/NextLink';
import { useRouter } from 'next/router';
import cn from 'classnames';

import type { TRouter } from '@local-types/global';

import UXCGIcon from '@icons/UXCGIcon';
import UXCPIcon from '@icons/UXCPIcon';
import UXCatIcon from '@icons/UXCatIcon';
import UXCoreIcon from '@icons/UXCoreIcon';

import styles from './PageSwitcher.module.scss';

type TPageSwitcher = {
  page?: 'uxcore' | 'uxcg' | 'uxcp' | 'uxeducation' | 'uxcat';
};

const PageSwitcher: FC<TPageSwitcher> = ({ page }) => {
  const router = useRouter();
  const { locale } = router as TRouter;
  const bobUrl =
    'https://chat.openai.com/g/g-BtuSiGF18-bob-trickery-and-deception-by-ux-core';
    
  const isProduction = process.env.NEXT_PUBLIC_ENV === 'prod';

  const handleKeyPress = useCallback(
    (e: { shiftKey: any; ctrlKey: any; keyCode: number }) => {
      if (e.shiftKey && e.ctrlKey) {
        if (e.keyCode == 49) {
          router.push('/uxcore', null);
        }

        if (e.keyCode == 50) {
          router.push('/uxcg', null);
        }
      }
    },
    [],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const isUXCore = page === 'uxcore';
  const isUXCG = page === 'uxcg';
  const isUXCP = page === 'uxcp';
  const isUXCat = page === 'uxcat';

  return (
    <div className={styles.PageSwitcher}>
      <div className={styles.GridWrapper}>
        <Link href="/uxcore">
          <a
            className={cn(styles.Button, {
              [styles.Active]: isUXCore,
            })}
          >
            <UXCoreIcon />
            <span className={styles.Description}>UX CORE</span>
          </a>
        </Link>
        <Link href="/uxcg">
          <a
            className={cn(styles.Button, {
              [styles.Active]: isUXCG,
            })}
          >
            <UXCGIcon />
            <span className={styles.Description}>GUIDE</span>
          </a>
        </Link>
        <Link href="/uxcp">
          <a
            className={cn(styles.Button, {
              [styles.Active]: isUXCP,
            })}
          >
            <UXCPIcon />
            <span className={styles.Description}>PERSONA</span>
          </a>
        </Link>
        {
          !isProduction && (
            <Link href="/uxcat">
              <a
                className={cn(styles.Button, {
                  [styles.Active]: isUXCat,
                })}
              >
                <UXCatIcon />
                <span className={styles.Description}>UX CAT</span>
              </a>
            </Link>
          )
        }
        <Link href={bobUrl}>
          <a target={'_blank'} className={styles.Button}>
            <img
              src={'/assets/Bob.png'}
              alt={'Bob - AI Assistant'}
              width={30}
              height={30}
              className={styles.bob}
            />
            <span className={styles.Description}>
              {locale === 'en' ? 'Bob - AI Assistant' : 'Боб - ИИ Ассистент'}
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default PageSwitcher;
