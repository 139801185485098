const ru = {
  shortTitle: '(uxCAT)',
  title: 'UXC Игра на Внимание',
  guestUsername: 'Гость Пользователь',
  accordionTitle: 'Как это работает?',
  description: `Это единственный тест где вы можете проверить свою способность 
  замечать паттерны мышления (когнитивные искажения) в реальных ситуациях. 
  Проходите тесты ежедневно. Копите очки, получайте уровни и разблокируйте 
  уникальные достижения, попутно повышая свой навык принятия решений. 
  Тест бесплатный, как и все в этом проекте.`,
  awarenessPointsTxt:
    'Очки Внимательности: Пройдите первый тест, чтобы получить очки.',
  startBtn: 'Начать Тест',
  myProfileTxt: 'Мой Профиль',
  lvlProgression: 'Прогресс уровней',
  achievementTxt: 'Достижения',
  showAchievementsTxt: 'Показать все достижения',
  yourPointsTxt: 'Ваши очки внимания:',
  nextTestIn: 'Следующий тест через',
  minutesTxtShort: 'м.',
  hoursTxtShort: 'ч.',
  secondsTxtShort: 'с.',
  continueTest: 'Продолжить Тест',
  finalTestTxt: 'Финальный Тест',
  achievedOnlyTxt: 'Только полученные',
  allTxt: 'Все',
  showAchievementDropdown: 'Показать',
  zeroLevelUser: 'Гость',
  hiddenAchievement: 'Скрыто',
  locked: 'Не разблокировано еще',
  unlockedTxt: 'Разблокировано',
  hiddenTooltip:
    'Критерии неизвестны. Проходите больше тестов и у вас будет возможность получить это достижение.',
  general: 'Особые',
  special: 'Основные',
  usersHaveThis: '% пользователей имеют это',
  lvl: 'Уровень',
  lvlShort: 'Уровень',
};

export default ru;
