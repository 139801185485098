const en = {
  usefulLinksLabel: 'Useful Links',
  usernameIsTaken: 'Username is already taken. Please try another one.',
  settingsTxt: 'Settings',
  myProfileTxt: 'My Profile',
  bobName: 'Bob - AI Assistant',
  awarenessTest: 'Awareness Test',
  usefulLinks: [
    {
      title: '',
      items: [
        {
          title: 'Cognitive biases in education',
          link: '/uxeducation',
        },
      ],
    },
    {
      title: 'UX CORE',
      items: [
        {
          title: 'Introduction article',
          icon: 'medium-logo',
          link: 'https://medium.com/@alexanyanwolf/the-science-of-user-experience-b4ae1314712c',
          target: '_blank',
          alt: 'Medium.com publishing platform logo',
        },
        {
          title: 'Podcast about UX Core',
          link: 'https://itx.com/podcasts/51-cognitive-bias-and-software-development/',
          target: '_blank',
        },
      ],
    },
    {
      title: 'UX CORE GUIDE',
      items: [
        {
          title: 'Introduction article',
          icon: 'medium-logo',
          link: 'https://medium.com/@alexanyanwolf/cognitive-science-and-user-experience-a-new-dimension-of-abstract-802c58bdb6e1',
          target: '_blank',
          alt: 'Medium.com publishing platform logo',
        },
        {
          title: 'Add your question',
        },
        {
          title: 'How this tool was built',
          link: '/uxcgdiy',
          target: '_blank',
        },
        {
          title: 'Download offline version (PDF)',
          link: '/static/uxcg/en/uxcg_complete_english.zip',
          target: '_blank',
          download: true,
        },
      ],
    },
    {
      title: 'UX CORE PERSONA',
      items: [
        {
          title: 'Introduction Article',
          icon: 'medium-logo',
          link: 'https://medium.com/@alexanyanwolf/creating-user-persona-with-cognitive-biases-and-ux-core-cb5a03a897a9',
          target: '_blank',
          alt: 'Medium.com publishing platform logo',
        },
      ],
    },
    {
      title: '',
      items: [
        {
          title: 'API',
          icon: 'api',
          link: '/uxcore-api',
          target: '',
          alt: 'UX Core API',
        },
      ],
    },
  ],
};

export default en;
