import Link from 'next/link';
import { FC, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { logout } from '@api/auth';
import { NextRouter, useRouter } from 'next/router';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { TRouter } from '@local-types/global';

import decisionTable from '@data/decisionTable';

import styles from './UserDropdown.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';

type UserDropdownProps = {
  userName?: string;
  userImage?: string;
  showDropdown?: boolean;
  showSavedPersonas?: boolean;
  isLoggedIn?: boolean;
  setShowDropdown?: (updater: (prev: boolean) => boolean) => void;
  setAccountData?: (updater: (prev: boolean) => boolean) => void;
  openSavedPersonas?: (savedPersonas: boolean) => void;
  handleOpenSettings?: () => void;
  settingsTxt: string;
  myProfileTxt: string;
};

const UserDropdown: FC<UserDropdownProps> = ({
  userName,
  userImage,
  showDropdown,
  setShowDropdown,
  showSavedPersonas,
  isLoggedIn,
  setAccountData,
  openSavedPersonas,
  handleOpenSettings,
  settingsTxt,
  myProfileTxt,
}) => {
  const router: NextRouter = useRouter();
  const { locale } = useRouter() as TRouter;
  const { savedPersonasText, logoutText, login } = decisionTable[locale];
  const [isAccessTokenExist, setIsAccessTokenExist] = useState(false);

  const isProduction = process.env.NEXT_PUBLIC_ENV === 'prod';

  const toggleDropdownView = useCallback(e => {
    e.stopPropagation();
    setShowDropdown(prev => !prev);
  }, []);

  const handleLogout = useCallback(e => {
    setAccountData(null);
    logout();
    document.cookie = `accessToken=; path=/; Secure; SameSite=Strict;`;
    toggleDropdownView(e);
  }, []);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('click', toggleDropdownView);
      return () => {
        document.removeEventListener('click', toggleDropdownView);
      };
    }
  }, [showDropdown]);

  useEffect(() => {
    localStorage.setItem('link', router.asPath);
  }, []);

  const renderUserName = () => {
    if (!isLoggedIn && isAccessTokenExist) {
      return <Skeleton width={100} />;
    }
    return userName;
  };

  const renderUserImage = () => {
    if (!isLoggedIn && isAccessTokenExist) {
      return '/assets/images/userProfile.svg';
    }
    return userImage;
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      setIsAccessTokenExist(true);
    }
  }, []);

  return (
    <div
      onClick={isLoggedIn ? toggleDropdownView : () => {}}
      className={styles.userContainer}
    >
      {isAccessTokenExist ? (
        <div
          className={cn(styles.user, {
            [styles.active]: showDropdown,
          })}
        >
          <Image
            src={renderUserImage()}
            alt="pic"
            width={32}
            height={32}
            className={styles.image}
          />
          <span className={styles.userName}> {renderUserName()}</span>
        </div>
      ) : (
        <Link href={'/auth'}>
          <div
            className={cn(styles.user, {
              [styles.active]: showDropdown,
            })}
          >
            <Image
              src={'/assets/images/userProfile.svg'}
              alt="pic"
              width={32}
              height={32}
              className={styles.image}
            />
            <span className={styles.userName}>{login}</span>
          </div>
        </Link>
      )}
      <div
        className={cn(styles.dropdown, {
          [styles.showed]: showDropdown,
        })}
      >
        {!isProduction && <Link href={`/user/${userName}`}>
          <span className={styles.myProfile}>{myProfileTxt}</span>
        </Link>}
        {!isProduction && (
          <span className={styles.settings} onClick={handleOpenSettings}>
            {settingsTxt}
          </span>
        )}
        <span className={styles.userNameDropdown}> {userName}</span>
        {showSavedPersonas && (
          <span
            className={styles.savedPersonas}
            onClick={() => !!userName && openSavedPersonas(true)}
          >
            {savedPersonasText}
          </span>
        )}
        <span onClick={handleLogout} className={styles.logOut}>
          {logoutText}
        </span>
      </div>
    </div>
  );
};

export default UserDropdown;
