const ru = {
  title: 'Таблица решений',
  learnHowToUse: 'Узнайте, как использовать этот инструмент',
  tableExampleText: 'Пример таблицы решений',
  article: 'Статья',
  image: 'Картинка',
  tableTitles: [
    {
      toolTipContent: 'Предложенное решение',
      title: 'Что мы делаем?',
    },
    {
      toolTipContent: 'Объяснение предлагаемого решения',
      title: 'Почему (искажение)?',
    },
    {
      toolTipContent: 'Объяснение предлагаемого решения',
      title: 'Почему?',
    },
    {
      toolTipContent: 'Методы, используемые для идентификации искажений',
      title: 'Методы валидации',
    },
  ],
  exportBtnText: 'Экспорт',
  fullScreenBtn: 'Во весь экран',
  saveBtn: 'Сохранить в профиль',
  saveTxt: 'Сохранить',
  savedState: 'Сохранено',
  loginToEdit: 'Войдите, чтобы редактировать',
  login: 'Войти',
  logoutText: 'Выйти',
  closeBtn: 'Закрыть',
  savedPersonasTitles: [
    'Имя персоны',
    'Дата создания',
    'Последнее изменение',
    'Действия',
  ],
  open: 'Открыть',
  deleteBtn: 'Удалить',
  loginToSaveText:
    'Чтобы редактировать и сохранить таблицу решений, вам нужно войти в систему.',
  singInWithGoogle: 'Войти с помощью Google',
  copyLink: 'Скопировать',
  cancelBtn: 'Отмена',
  availablePersonasTxt: 'Создано персон:',
  personasIsFull:
    'Вы достигли лимита допустимых Персон.\n Вы можете удалить одну из персон на странице “Сохраненные Персоны”',
  overWriteText: 'Перезаписать',
  savedPersonasText: 'Сохраненные Персоны',
};

export default ru;
