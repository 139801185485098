const en = {
  info1: `Here you can submit your question to be added to the UXCG.`,
  info2: `The question should be generic, not be answered in any form by the existing questions, and address a significant, generic issue.`,
  info3: `I will spend about an hour and a half of my time working on each question, so adding it may take some time. If you enter your name in the form below, it will be displayed under the question in its pop-up. Thank you.`,
  questionLabel: 'Your question',
  questionError: `field is required`,
  tagsLabel: 'Suggested tags',
  nameLabel: 'Your name',
  namePlaceholder: 'Name',
  nameHint:
    'Please use your real name or nickname. It will be shown in the question pop-up in case of its adding to the project.',
  emailLabel: 'Your email',
  emailPlaceholder: 'Email',
  emailError: `invalid email`,
  cancelButtonLabel: `Cancel`,
  submitButtonLabel: `Submit`,
};

export default en;
