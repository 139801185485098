const ru = {
  usefulLinksLabel: 'Полезные ссылки',
  usernameIsTaken: 'Имя пользователя уже занято. Пожалуйста попробуйте другое',
  settingsTxt: 'Настройки',
  myProfileTxt: 'Мой профиль',
  bobName: 'Боб - ИИ Ассистент',
  awarenessTest: "Игра на внимание",
  usefulLinks: [
    {
      title: '',
      items: [
        {
          title: 'Использование проекта в образовательной системе',
          link: '/ru/uxeducation',
        },
      ],
    },
    {
      title: 'UX CORE',
      items: [
        {
          title: 'Вводная статья',
          icon: 'habr-logo',
          link: 'https://habr.com/ru/post/512842/',
          target: '_blank',
          alt: 'Habr.com publishing platform logo',
        },
        {
          title: 'Аудио подкаст о UX Core',
          link: 'https://itx.com/podcasts/51-cognitive-bias-and-software-development/',
          target: '_blank',
        },
      ],
    },
  {
      title: 'UX CORE GUIDE',
      items: [
        {
          title: 'Вводная статья',
          icon: 'habr-logo',
          link: 'https://habr.com/ru/post/568116/',
          target: '_blank',
          alt: 'Habr.com publishing platform logo',
        },
        {
          title: 'Добавление вашего вопроса',
        },
        {
          title: 'История создания UXCG',
          link: '/uxcgdiy',
          target: '_blank',
        },
        {
          title: 'Скачать оффлайн версию проекта (PDF)',
          link: '/static/uxcg/ru/uxcg_complete_russian.zip',
          target: '_blank',
          download: true,
        },
      ],
    },
    {
      title: 'UX CORE PERSONA',
      items: [
        {
          title: 'Вводная статья',
          icon: 'habr-logo',
          link: 'https://habr.com/ru/post/670722/',
          alt: 'Habr.com publishing platform logo',
        },
      ],
    },
    {
      title: '',
      items: [
        {
          title: 'API',
          icon: 'api',
          link: '/ru/uxcore-api',
          target: '',
          alt: 'UX Core API',
        },
      ],
    },
  ],
};

export default ru;
