const ru = {
  feedbackLabel: `Ваш отзыв жизненно важен для нашего проекта`,
  feedbackError: `Это поле не может быть пустым`,
  emailLabel: `Ваш почтовый адрес (опционально)`,
  emailPlaceholder: `@ Адрес`,
  emailError: `Не верный адрес`,
  info1: `Вы можете написать Вольфу на `,
  info2: `либо на почту`,
  cancelButtonLabel: `Закрыть`,
  submitButtonLabel: `Отправить`,
};

export default ru;
