import { useEffect, FC, useState } from 'react';
import { useRouter } from 'next/router';
import { getCountributors } from '@api/contributors';

import ContentParser from '@components/ContentParser';
import CopyButton from '@components/CopyButton';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';

import type { TRouter } from '@local-types/global';
import contributors from '@data/contributors';

import styles from './Contributors.module.scss';
import defaultParserStyles from '../../../UXCoreModal/UXCoreModal.module.scss';

const coins = [
  {
    name: 'BTC',
    fullName: 'Bitcoin',
    address: 'bc1qzh2vwdrh95wx8sjgzfq2ur7clstwwhnq0y9txl',
  },
  {
    name: 'ETH',
    fullName: 'Ethereum',
    address: '0x899013766E100989a2fAF809df9aB86814eE75a8',
  },
  {
    name: 'USDT',
    fullName: 'Tether',
    address: '0x899013766E100989a2fAF809df9aB86814eE75a8',
  },
];

type ContributorsProps = {
  closeModal: () => void;
};
type Data = {
  en: string;
  ru: string;
};

const Contributors: FC<ContributorsProps> = ({ closeModal }) => {
  const router = useRouter();
  const { locale } = router as TRouter;
  const { cancelButtonLabel } = contributors[locale];
  const [data, setData] = useState<Data>({ en: '', ru: '' });

  useEffect(() => {
    getCountributors().then(res => setData(res));
  }, []);
  return (
    <div className={styles.Contributors}>
      <div className={defaultParserStyles.ModalBodyContent}>
        <ContentParser data={data[locale]} styles={defaultParserStyles} />
      </div>
      <div className={styles.Currencies}>
        {coins.map(({ name, fullName, address }, index) => (
          <div key={index} className={styles.Account}>
            <div className={styles.Coin}>
              <Tooltip content={fullName}>
                <img src={`/assets/icons/${name.toLocaleLowerCase()}.svg`} />{' '}
              </Tooltip>
              <span>{name}:</span>
            </div>
            <div className={styles.Number}>{address}</div>
            <CopyButton stringToCopy={address} />
          </div>
        ))}
      </div>
      <div className={styles.Footer}>
        <div className={styles.Buttons}>
          <Button label={cancelButtonLabel} onClick={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default Contributors;
