const ru = {
  title: 'Настройки',
  selectTitle: 'Звание',
  usernameTxt: 'Имя пользователя',
  email: 'Электронная почта',
  visible: 'Видно:',
  everyone: 'Всем',
  onlyYou: 'Только мне',
  linkedIn: 'LinkedIn профиль',
  saveBtn: 'Сохранить',
  cancelBtn: 'Отмена',
  usernameValidationMessage: "Имя пользователя должно быть длиной от 6 до 30 символов и не содержать пробелов"
};

export default ru;
