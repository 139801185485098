import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Link from '@components/NextLink';
import { useRouter } from 'next/router';
import type { TRouter } from '@local-types/global';
import Image from 'next/image';
import cn from 'classnames';

import { GlobalContext } from '@components/Context/GlobalContext';
import UserDropdown from '@components/UserDropdown';
import SettingsModal from "@components/SettingsModal";

import PodcastIcon from '@icons/PodcastIcon';

import { isLevelMilestone } from "@lib/uxcat-helpers";

import toolHeaderData from "@data/toolHeader";

import { userInfoUpdate } from "@api/uxcat/settings";
import { getMyInfo } from "@api/strapi";
import { getUserInfo } from "@api/uxcat/users-me";

import { UserTypes } from "@local-types/uxcat-types/types";

import styles from './MobileHeader.module.scss';

type MobileHeaderProps = {
  setHeaderPodcastOpen?: (updater: (prev: boolean) => boolean) => void;
  setUpdatedSettingsInfo?: (data: UserTypes) => void;
  isPodcastOpen?: boolean;
  changeUserUrl?: boolean;
  instantSave?: boolean;
  isUserProfile?: boolean;
  setSelectedTitle?: (title: string) => void;
};
const MobileHeader: FC<MobileHeaderProps> = ({
  setHeaderPodcastOpen,
  isPodcastOpen,
  changeUserUrl,
  setUpdatedSettingsInfo,
  instantSave,
  setSelectedTitle,
  isUserProfile
}) => {
  const router = useRouter();
  const { locale, asPath } = router as TRouter;
  const oppositeLocale = locale === 'ru' ? 'en' : 'ru';
  const { accountData, setAccountData } = useContext(GlobalContext);
  const {
    usernameIsTaken,
    settingsTxt,
    myProfileTxt,
  } = toolHeaderData[locale];
  const imageSrc = useMemo(() => accountData?.picture, [accountData]);
  const [userInfo, setUserInfo] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [usernameIsTakenError, setUsernameIsTakenError] = useState('');
  const [updatedStrapiUserInfo, setUpdatedStrapiUserInfo] =
    useState(accountData);
  const [changedTitle, setChangedTitle] = useState(false);
  const changeTitlePermission = isLevelMilestone(userInfo?.level, 'M3');

  // Mary - DRY Toolheader
  const userTitlesRu = ['Просвещенный', 'Профессор', 'Великий'];
  const title = changedTitle ? updatedStrapiUserInfo?.title : userInfo?.title;

  const russianTitles = selectedTitle => {
    if (!selectedTitle) {
      return null;
    }
    return selectedTitle === 'Enlightened'
      ? userTitlesRu[0]
      : selectedTitle === 'Professor'
        ? userTitlesRu[1]
        : userTitlesRu[2];
  };

  const currentUsername = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.username
    : accountData?.username;
  const currentEmail = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.email
    : accountData?.email;
  const publicEmail = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.publicEmail
    : accountData?.publicEmail;
  const linkedIn = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.linkedin
    : accountData?.linkedin;
  const linkedInStatus = updatedStrapiUserInfo
    ? updatedStrapiUserInfo.publicLinkedin
    : accountData?.publicLinkedin;


  const openPodcast = () => {
    setHeaderPodcastOpen(prev => !prev);
  };
  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleSaveClick = async (
    username: string,
    linkedInUrl: string,
    isEmailPublic: string,
    isLinkedinPublic: string,
    title: string,
  ) => {
    const mailIsPublic = isEmailPublic === 'everyone';
    const linkedInIsPublic = isLinkedinPublic === 'everyone';
    try {
      await userInfoUpdate(
        token,
        username,
        linkedInUrl,
        mailIsPublic,
        linkedInIsPublic,
        title,
      );
      const data = await getMyInfo();
      changeUserUrl && router.replace(`/user/${username}`);
      setUpdatedStrapiUserInfo(data);
      instantSave && setUpdatedSettingsInfo(data);
      setOpenSettings(false);
      setUsernameIsTakenError('');
    } catch (error) {
      setOpenSettings(true);
      setUsernameIsTakenError(usernameIsTaken);
    }
  };

  useEffect(() => {
    const token =
      (typeof window !== undefined && localStorage.getItem('accessToken')) ||
      localStorage.getItem('googleToken');
    setToken(token);
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await getUserInfo();
        setUserInfo(data?.user);
      } catch (err) {
        setUserInfo(err.message);
      }
    };

    if (token) {
      fetchUserInfo().then(r => r);
    }
  }, [token]);

  useEffect(() => {
    if (isUserProfile) {
      setSelectedTitle && setSelectedTitle(locale === 'en' ? title : russianTitles(title));
    }
  }, [title, locale]);

  return (
    <div className={styles.MobileHeader}>
      <div className={styles.SiteName}>
        <a href={`/${locale === "ru" ? "ru" : ""}`} target="_self">
          <Image
            src="/assets/logos/keepsimple.svg"
            alt="keepsimple logo"
            width={130.61}
            height={25.87}
            className={styles.logo}
          />
        </a>
      </div>
      <div className={styles.Actions}>
        {router.asPath === '/uxcore' && (
          <div
            className={cn(styles.PodcastWrapper, {
              [styles.active]: isPodcastOpen,
            })}
            onClick={openPodcast}
          >
            <PodcastIcon />
          </div>
        )}
        <Link href={asPath} locale={oppositeLocale}>
          <a className={styles.LocaleBar}>{oppositeLocale}</a>
        </Link>
        <UserDropdown
          userName={currentUsername}
          userImage={imageSrc}
          showDropdown={showDropdown}
          isLoggedIn={!!accountData}
          setShowDropdown={setShowDropdown}
          setAccountData={setAccountData}
          handleOpenSettings={handleOpenSettings}
          settingsTxt={settingsTxt}
          myProfileTxt={myProfileTxt}
        />

      </div>
      {openSettings && (
        <SettingsModal
          setOpenSettings={setOpenSettings}
          currentUsername={currentUsername}
          currentEmail={currentEmail}
          mailStatus={publicEmail}
          linkedin={linkedIn}
          linkedinStatus={linkedInStatus}
          handleSaveClick={handleSaveClick}
          setUsernameIsTakenError={setUsernameIsTakenError}
          usernameIsTakenError={usernameIsTakenError}
          defaultSelectedTitle={locale === 'en' ? title : russianTitles(title)}
          changeTitlePermission={changeTitlePermission}
          setChangedTitle={setChangedTitle}
        />
      )}
    </div>
  );
};

export default MobileHeader;
