const ru = {
  info1: `Здесь вы можете отправить запрос на добавление вашего вопроса в UXCG.`,
  info2: `Вопрос должен быть общим. Вопрос не должен встречаться в уже существующих материалах. Вопрос должен выявлять серъезную, популярную проблему.`,
  info3: `На добавление каждого вопроса я трачу в среднем полтора часа. Из-за моей рабочей занятости вопрос вопрос будет добавлен с небольшой задержкой. Если вы заполните поле "Имя", оно будет добавлено на страницу этого вопроса. Большое спасибо.`,
  questionLabel: 'Ваш вопрос',
  questionError: `Вы не можете оставить это поле пустым`,
  tagsLabel: 'Предлагаемые лейблы',
  nameLabel: 'Ваше имя',
  namePlaceholder: 'Введите ваше имя',
  nameHint:
    'Пожалуйста используйте ваше имя или никнейм. Используемое имя будет отображено в поп-апе вопроса, если он будет добавлен.',
  emailLabel: '@ адрес',
  emailPlaceholder: 'Введите адрес вашей почты',
  emailError: `Не верный почтовый адрес`,
  cancelButtonLabel: `Отмена`,
  submitButtonLabel: `Отправить`,
};

export default ru;
