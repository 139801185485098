const PodcastIcon = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.875 14.5059C14.0531 13.8257 15.0315 12.8474 15.7117 11.6692C16.3919 10.4911 16.75 9.15464 16.75 7.79423C16.75 6.43382 16.3919 5.09738 15.7117 3.91923C15.0315 2.74108 14.0531 1.76274 12.875 1.08253L13.5 0C14.8682 0.789915 16.0043 1.92606 16.7942 3.29423C17.5841 4.6624 18 6.2144 18 7.79423C18 9.37406 17.5841 10.9261 16.7942 12.2942C16.0043 13.6624 14.8682 14.7985 13.5 15.5885L12.875 14.5059ZM2.9119 2.99865C3.49445 2.25909 4.20688 1.63185 5.01429 1.14768L4.37143 0.0756569C3.4338 0.637922 2.60646 1.36632 1.92995 2.22517L2.9119 2.99865ZM1.3163 6.78265C1.44915 5.77361 1.77943 4.80062 2.2883 3.91923L1.20577 3.29423C0.614824 4.31778 0.231266 5.4477 0.0769968 6.61949C-0.0772719 7.79128 0.000770306 8.98197 0.306668 10.1236C0.612565 11.2652 1.14033 12.3354 1.85982 13.2731C2.57931 14.2107 3.47645 14.9975 4.5 15.5885L5.125 14.5059C4.24361 13.9971 3.47108 13.3196 2.85151 12.5121C2.23195 11.7047 1.77749 10.7831 1.51407 9.80007C1.25066 8.81701 1.18346 7.79169 1.3163 6.78265ZM4.88638 5.41923C5.30328 4.69714 5.90291 4.09751 6.625 3.68061L6 2.59807C5.08789 3.12468 4.33046 3.88211 3.80385 4.79423C3.27724 5.70634 3 6.74101 3 7.79423C3 8.84745 3.27724 9.88211 3.80385 10.7942C4.33046 11.7063 5.08788 12.4638 6 12.9904L6.625 11.9078C5.90291 11.4909 5.30328 10.8913 4.88638 10.1692C4.46948 9.44714 4.25 8.62803 4.25 7.79423C4.25 6.96043 4.46948 6.14132 4.88638 5.41923ZM11.375 11.9078C12.0971 11.4909 12.6967 10.8913 13.1136 10.1692C13.5305 9.44714 13.75 8.62803 13.75 7.79423C13.75 6.96043 13.5305 6.14132 13.1136 5.41923C12.6967 4.69714 12.0971 4.09751 11.375 3.68061L12 2.59808C12.9121 3.12469 13.6695 3.88211 14.1962 4.79423C14.7228 5.70634 15 6.74101 15 7.79423C15 8.84745 14.7228 9.88211 14.1962 10.7942C13.6695 11.7063 12.9121 12.4638 12 12.9904L11.375 11.9078ZM9 9.79423C10.1046 9.79423 11 8.8988 11 7.79423C11 6.68966 10.1046 5.79423 9 5.79423C7.89543 5.79423 7 6.68966 7 7.79423C7 8.8988 7.89543 9.79423 9 9.79423Z"
      fillOpacity="0.65"
    />
  </svg>
);

export default PodcastIcon;
